<template>
  <CreateUpdateTemplate
    :customClass="'expense-create'"
    v-if="getPermission('expense:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        Create new Expense For
        <p
          class="m-0 form-title-create-link pl-2"
          :class="{
            'text-ellipsis width-350px': !lodash.isEmpty(customerObject),
          }"
          link
        >
          <template v-if="lodash.isEmpty(customerObject)">Client Name</template>
          <template v-else>{{ customerObject.display_name }}</template>
          <v-icon
            link
            large
            color="cyan"
            class="mx-2"
            v-if="lodash.isEmpty(customerObject)"
            >mdi-plus-circle-outline</v-icon
          >
        </p>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate('billable')"
        color="cyan"
      >
        Save as Billable
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate('non_billable')"
        color="cyan"
      >
        Save as Non Billable
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="expenseForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate('')"
      >
        <v-container fluid>
          <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 70vh; position: relative"
          > -->
          <v-row>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="200">Expense Title</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Title"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.trim="expenseCreate.title"
                      dense
                      filled
                      label="Title"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      :rules="[
                        validateRules.required(
                          expenseCreate.title,
                          'Expense Title'
                        ),
                        validateRules.minLength(
                          expenseCreate.title,
                          'Expense Title',
                          1
                        ),
                        validateRules.maxLength(
                          expenseCreate.title,
                          'Expense Title',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Project</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Project"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="projectList"
                      label="Project"
                      solo
                      flat
                      :disabled="pageLoading || !!+$route.query.project"
                      :loading="pageLoading"
                      hide-details
                      item-text="name"
                      item-value="id"
                      v-model.trim="expenseCreate.project"
                      :rules="[
                        validateRules.required(
                          expenseCreate.project,
                          'Project'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Project Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >#{{ item.barcode }} -
                            {{ item.name }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Payment Mode</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Payment Mode"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="paymentModeList"
                      label="Payment Mode"
                      solo
                      flat
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      hide-details
                      item-text="text"
                      item-value="id"
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="managePaymentModeDialog = true"
                      v-model.trim="expenseCreate.payment_mode"
                      :rules="[
                        validateRules.required(
                          expenseCreate.payment_mode,
                          'Payment Mode'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Project Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Expense Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Expense Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      solo
                      :pageLoading.sync="pageLoading"
                      :placeholder="'Expense Date'"
                      v-model="expenseCreate.expense_date"
                    ></DatePicker>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="200">Expense number</td>
                  <td class="font-weight-700 font-size-16 pb-4" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        readonly
                        v-model.trim="expenseCreate.barcode"
                        label="Expense number"
                        solo
                        flat
                        hide-details
                        class="remove-border cursor-default"
                      ></v-text-field>
                    </template>
                  </td>
                  <td class="font-size-16 pb-4">
                    <v-btn
                      text
                      small
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      class="mx-2 custom-bold-button"
                      v-on:click="barcodeDialog = true"
                      color="cyan"
                    >
                      Change
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Reference #</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Reference #"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="expenseCreate.reference"
                      label="Reference #"
                      :rules="[
                        validateRules.minLength(
                          expenseCreate.reference,
                          'Reference',
                          1
                        ),
                        validateRules.maxLength(
                          expenseCreate.reference,
                          'Reference',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">
                    Expense Category
                  </td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Expense Category"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="expenseCategoryList"
                      label="Expense Category"
                      solo
                      flat
                      hide-details
                      item-text="text"
                      item-value="value"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="
                        manageExpenseCategoryDialog = true
                      "
                      v-model.trim="expenseCreate.expense_category"
                      :rules="[
                        validateRules.required(
                          expenseCreate.expense_category,
                          'Expense Category'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Expense Category Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Expense Amount</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Expense Amount"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.trim="expenseCreate.amount"
                      dense
                      filled
                      label="Expense Amount"
                      solo
                      flat
                      hide-details
                      type="number"
                      min="0"
                      prepend-inner-icon="mdi-currency-usd"
                      :rules="[
                        validateRules.required(
                          expenseCreate.amount,
                          'Expense Amount'
                        ),
                      ]"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      v-on:keypress="limitDecimal($event, expenseCreate.amount)"
                    ></v-text-field>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="12" class="py-0">
              <editor v-model="expenseCreate.description" />
            </v-col>
            <v-col md="12">
              <FileTemplate
                allowUpload
                v-on:file:updated="updateAttachment"
              ></FileTemplate>
            </v-col>
          </v-row>
          <!-- </perfect-scrollbar> -->
        </v-container>
      </v-form>
      <ManageExpenseCategory
        :commonDialog.sync="manageExpenseCategoryDialog"
        :expenseCategory.sync="expenseCategoryList"
        v-on:close-dialog="manageExpenseCategoryDialog = false"
        v-on:get-expense-category="getOptions(['expense_category'])"
      ></ManageExpenseCategory>
      <ManagePaymentMode
        :commonDialog.sync="managePaymentModeDialog"
        :paymentMode.sync="paymentModeList"
        v-on:close-dialog="managePaymentModeDialog = false"
        v-on:get-payment-mode="getOptions(['payment_mode'])"
      ></ManagePaymentMode>
    </template>
    <template v-slot:barcode-setting>
      <BarcodeSetting
        endpoint="expense"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting.sync="barcodeSetting"
        v-on:update:barcode-setting="getOptions(['barcode_setting', 'barcode'])"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CLEAR_ERROR, POST, QUERY } from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import ManageExpenseCategory from "@/view/pages/partials/Manage-Expense-Category.vue";
import ManagePaymentMode from "@/view/pages/partials/Manage-Payment-Mode.vue";
import moment from "moment-timezone";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "expense-create",
  title: "Create Expense",
  data() {
    return {
      pageLoading: false,
      barcodeDialog: false,
      manageExpenseCategoryDialog: false,
      managePaymentModeDialog: false,
      barcodeSetting: new Object(),
      customerObject: new Object(),
      expenseCategoryList: new Array(),
      projectList: new Array(),
      paymentModeList: new Array(),
      expenseCreate: new Object({
        reference: null,
        customer: null,
        project: null,
        title: null,
        amount: null,
        expense_category: null,
        payment_mode: null,
        description: null,
        expense_date: null,
        status: null,
        attachments: [],
      }),
    };
  },
  components: {
    DatePicker,
    FileTemplate,
    BarcodeSetting,
    ManagePaymentMode,
    ManageExpenseCategory,
    CreateUpdateTemplate,
    editor: TinyMCE,
  },
  methods: {
    updateAttachment(param) {
      this.expenseCreate.attachments = param;
    },
    getOptions(attributes) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "expenses/options",
          data: { attributes, customer: _this.expenseCreate.customer },
        })
        .then(({ data }) => {
          if (_this.lodash.isEmpty(data.customer) === false) {
            _this.customerObject = data.customer;
          }
          if (_this.lodash.isEmpty(data.barcode_setting) === false) {
            _this.barcodeSetting = data.barcode_setting;
          }
          if (_this.lodash.isEmpty(data.barcode) === false) {
            _this.expenseCreate.barcode = data.barcode;
          }
          if (_this.lodash.isEmpty(data.expense_category) === false) {
            _this.expenseCategoryList = data.expense_category;
          }
          if (_this.lodash.isEmpty(data.project_list) === false) {
            _this.projectList = data.project_list;
          }
          if (_this.lodash.isEmpty(data.payment_mode) === false) {
            _this.paymentModeList = data.payment_mode;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.manageExpenseCategoryDialog = false;
        });
    },
    updateOrCreate(status) {
      const _this = this;

      const validateStatus = _this.$refs.expenseForm.validate();

      const formErrors = _this.validateForm(_this.$refs.expenseForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        reference: _this.expenseCreate.reference || null,
        customer: _this.expenseCreate.customer || null,
        project: _this.expenseCreate.project || null,
        title: _this.expenseCreate.title || null,
        amount: _this.expenseCreate.amount || null,
        expense_category: _this.expenseCreate.expense_category || null,
        payment_mode: _this.expenseCreate.payment_mode || null,
        description: _this.expenseCreate.description || null,
        expense_date: _this.expenseCreate.expense_date || null,
        status: status || null,
        attachments: _this.expenseCreate.attachments || [],
      });

      _this.$store
        .dispatch(POST, {
          url: "expenses",
          data: formData,
        })
        .then(({ data }) => {
          _this.$router.push(
            _this.getDefaultRoute("expense.detail", {
              params: {
                id: data.id,
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  created() {
    const _this = this;
    _this.expenseCreate.customer = _this.lodash.toSafeInteger(
      _this.$route.query.customer
    );
    if (_this.expenseCreate.customer <= 0) {
      _this.goBack();
    }
    _this.expenseCreate.project = _this.lodash.toSafeInteger(
      _this.$route.query.project
    );
  },
  mounted() {
    const _this = this;
    _this.getOptions([
      "customer",
      "barcode_setting",
      "barcode",
      "expense_category",
      "payment_mode",
      "project_list",
    ]);

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Expense", route: "expense" },
      { title: "Create" },
    ]);
  },
};
</script>
